import React, { useEffect, useState } from 'react';
import './styles.scss';
import SundoraLogo from '../../images/icons/sundora-logo-vertical.png';
import { map } from 'lodash';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { RootStateOrAny, useSelector } from 'react-redux';
import { prefix } from '../../utils/customHooks/usePrefixSegment';
import { PREFIX_URL_DEFAULT, PREFIX_URL_KIDS, SUNDORA_KIDS_ACTIVE } from '../../config';
import SundoraKidsLogo from '../../images/Sundora-Toys-Logo.webp';
import { LOCALSTORAGE_KEY_USER_LOCATION } from '../../config';

function NavigationMobileCategory({ data = [], open, setOpen }: any) {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState<any>({
    index: null,
    active: false,
  });
  const [isOpenLastSub, setIsOpenLastSub] = useState<any>({
    index: null,
    active: false,
  });
  const globalConfigReducer = useSelector(
    (state: RootStateOrAny) => state.globalConfigReducer,
  );
  const userReducer = useSelector((state: RootStateOrAny) => state?.userReducer);

  const reRouteToRoot = (param = '') => {
    let redirect_url = window.location.origin;
    if (param != '') {
      redirect_url = window.location.origin + '/' + param + '/';
    }
    window.location.href = redirect_url;
  };

  useEffect(() => {
    if (prefix === PREFIX_URL_KIDS) {
      const items: any = document.querySelectorAll('.title-item');
      const colors = ['#DF1F26', '#F4A51D', '#3BA949', '#E3097E'];
      const len_colors = colors.length;
      let color_index = 0;
      for (let i = 0; i < items.length; i++) {
        items[i].style.cssText = `color: ${colors[color_index]} !important`;
        if (color_index + 1 < len_colors) {
          color_index++;
        } else {
          color_index = 0;
        }
      }
    }
  }, [data]);

  const handelActiveSubNav = (index: any) => {
    if (index == isOpen.index && isOpen.active) {
      setIsOpen({
        index: null,
        active: false,
      });
    } else
      setIsOpen({
        index: index,
        active: true,
      });
  };
  const handelActiveLastSubNav = (index: any) => {
    if (index == isOpenLastSub.index && isOpenLastSub.active) {
      setIsOpenLastSub({
        index: null,
        active: false,
      });
    } else
      setIsOpenLastSub({
        index: index,
        active: true,
      });
  };

  // const handelCloseSubNav = () => {
  //   if (isOpen.active) {
  //     setIsOpen({
  //       index: null,
  //       active: false,
  //     });
  //   }
  // };
  // const handelCloseLastSubNav = () => {
  //   if (isOpenLastSub.active) {
  //     setIsOpenLastSub({
  //       index: null,
  //       active: false,
  //     });
  //   }
  // };

  const toggleNavMobile = () => {
    setOpen(!open);
  };

  const history = useHistory();

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <>
      <div className={`navigation-block-mobile ${open ? 'active' : ''}`}>
        <Link className="logo-sub-menu" to="/">
          <img
            style={{ paddingTop: 5 }}
            src={
              globalConfigReducer?.logo
                ? globalConfigReducer?.logo?.original
                : prefix === PREFIX_URL_KIDS
                ? SundoraKidsLogo
                : SundoraLogo
            }
            width={100}
            alt="sundora-logo"
          />
        </Link>
        <div className="close-nav">
          <button onClick={toggleNavMobile} data-name="close">
            <i className="gg-close"></i>
          </button>
        </div>
        <div className="navigation-wrapper">
          <ul className="navigation-content">
            {map(data, (item, index) => {
              if (item?.visible_only_to_logged_in_users) {
                if (
                  !userReducer?.user &&
                  localStorage.getItem(LOCALSTORAGE_KEY_USER_LOCATION) != 'BD'
                ) {
                  return;
                }
              }
              return (
                <li
                  key={index}
                  className={`navigation-content__item ${
                    isOpen.index === index && isOpen.active == true ? 'active' : ''
                  }`}
                  onClick={() => {
                    handelActiveSubNav(index);
                  }}
                >
                  <p className="title-item">
                    {item?.menu_link_icon && (
                      <img
                        className="menu-link-icon"
                        src={item?.menu_link_icon}
                        alt={item.title}
                      />
                    )}{' '}
                    {item?.redirect_url?.relative_url ? (
                      <a href={item?.redirect_url?.relative_url}>{item.title}</a>
                    ) : (
                      item.title
                    )}
                    <i className="gg-chevron-right"></i>
                  </p>
                  {item.children.length > 0 ? (
                    <ul
                      className={`sub-nav ${
                        isOpen.index === index && isOpen.active == true
                          ? 'active-sub'
                          : 'hidden-sub'
                      }`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {/* <Link className="logo-sub-menu" to="/">
                        <img
                          src={
                            globalConfigReducer?.logo
                              ? globalConfigReducer?.logo?.original
                              : prefix === PREFIX_URL_KIDS
                              ? SundoraKidsLogo
                              : SundoraLogo
                          }
                          width={100}
                          height={62}
                          alt="sundora-logo"
                        />
                      </Link> */}
                      {/* <div className="close-nav">
                        <div>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handelCloseSubNav();
                            }}
                            data-name="back"
                            className="menu-back-button"
                          >
                            <img src={backIcon} alt="back" />
                          </button>
                        </div>
                        <div>
                          <button onClick={toggleNavMobile} data-name="close">
                            <i className="gg-close"></i>
                          </button>
                        </div>
                      </div> */}
                      {map(item.children, (itemChild, index) => {
                        if (itemChild?.visible_only_to_logged_in_users) {
                          if (!userReducer?.user) {
                            return;
                          }
                        }
                        return (
                          <li
                            key={index}
                            onClick={() => {
                              handelActiveLastSubNav(index);
                            }}
                          >
                            {itemChild?.children.length > 0 ? (
                              <p
                                className={`title-item ${
                                  isOpenLastSub.index === index && isOpenLastSub.active == true
                                    ? 'active-last-sub'
                                    : ''
                                }`}
                              >
                                {itemChild?.menu_link_icon && (
                                  <img
                                    className="menu-link-icon"
                                    src={itemChild?.menu_link_icon}
                                    alt={itemChild.title}
                                  />
                                )}{' '}
                                {itemChild?.relative_url ? (
                                  <a href={itemChild?.relative_url}>{itemChild.title}</a>
                                ) : (
                                  itemChild.title
                                )}{' '}
                                <i className="gg-chevron-right"></i>
                              </p>
                            ) : (
                              <p className="title-item">
                                <a
                                  onClick={() => {
                                    history.push(itemChild.relative_url);
                                  }}
                                >
                                  {' '}
                                  {itemChild?.menu_link_icon && (
                                    <img
                                      className="menu-link-icon"
                                      src={itemChild?.menu_link_icon}
                                      alt={itemChild.title}
                                    />
                                  )}{' '}
                                  {itemChild.title}
                                </a>
                              </p>
                            )}
                            {itemChild?.children.length > 0 ? (
                              <ul
                                className={`last-sub ${
                                  isOpenLastSub.index === index && isOpenLastSub.active == true
                                    ? 'active-sub'
                                    : 'hidden-sub'
                                }`}
                              >
                                {/* <Link className="logo-sub-menu" to="/">
                                  <img
                                    src={
                                      globalConfigReducer?.logo
                                        ? globalConfigReducer?.logo?.original
                                        : prefix === PREFIX_URL_KIDS
                                        ? SundoraKidsLogo
                                        : SundoraLogo
                                    }
                                    width={100}
                                    height={62}
                                    alt="sundora-logo"
                                  />
                                </Link>
                                <div className="close-nav">
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handelCloseLastSubNav();
                                    }}
                                    data-name="back"
                                  >
                                    <img src={backIcon} alt="back" />
                                  </button>
                                </div> */}
                                {map(itemChild?.children, (itemSubChild, index) => {
                                  if (itemSubChild?.visible_only_to_logged_in_users) {
                                    if (!userReducer?.user) {
                                      return;
                                    }
                                  }

                                  return (
                                    <li key={index}>
                                      <a
                                        onClick={() => {
                                          history.push(itemSubChild.relative_url);
                                        }}
                                      >
                                        <span>
                                          {itemSubChild?.menu_link_icon && (
                                            <img
                                              className="menu-link-icon"
                                              src={itemSubChild?.menu_link_icon}
                                              alt={itemSubChild.title}
                                            />
                                          )}
                                          {itemSubChild.title}
                                        </span>
                                      </a>
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : (
                              ''
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    ''
                  )}
                </li>
              );
            })}
            {prefix == PREFIX_URL_DEFAULT && SUNDORA_KIDS_ACTIVE ? (
              <a
                className="kids-menu-item navigation-content__item"
                onClick={() => reRouteToRoot('toys')}
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '100%',
                  marginLeft: '-12px',
                }}
              >
                {/*
                <img src={SundoraKidsIcon} style={{ height: '22px', marginRight: '5px' }} />
              */}
                SUNDORA TOYS
              </a>
            ) : prefix != PREFIX_URL_DEFAULT ? (
              <a
                className="beauty-menu-item navigation-content__item"
                onClick={() => reRouteToRoot('')}
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '100%',
                  marginLeft: '-12px',
                }}
              >
                {/*
                <img src={SundoraBeautyIcon} style={{ height: '22px', marginRight: '5px' }} />
              */}
                SUNDORA BEAUTY
              </a>
            ) : (
              <></>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}

export default NavigationMobileCategory;
