import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import SundoraLogo from '../../images/SundoraLogo_Beauty_svg.svg';
import SundoraKidsLogo from '../../images/Sundora-Toys-Logo.webp';
import SundoraSportLogo from '../../images/SUNDORA-SPORT-LOGO.png';
import SundoraHomeLogo from '../../images/SUNDORA-HOME-LOGO.png';
import SearchBox from './SearchBox';
// import './banner.scss';
import BagIcon from '../BagIcon';
import PhoneIcon from '../../images/icons/call.svg';
import FreeShippingIcon from '../../images/icons/freeShipping-white.svg';
import AuthIcon from '../../images/icons/auth-white.svg';
import UserIcon from '../../images/icons/user-profile.png';
import * as action from '../../redux/auth/actions';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
//import SundoraKidsIcon from '../../images/kids_icon_logo_comp.png';
//import SundoraBeautyIcon from '../../images/sundora-beauty-icon.png';

import {
  PHONE_CONTACT,
  //PREFIX_URL_DEFAULT,
  PREFIX_URL_HOME,
  PREFIX_URL_KIDS,
  PREFIX_URL_SPORTS,
} from '../../config';
import { prefix } from '../../utils/customHooks/usePrefixSegment';

function Banner(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef<any>(null);
  const userReducer = useSelector((state: RootStateOrAny) => state.userReducer);
  const globalConfigReducer = useSelector(
    (state: RootStateOrAny) => state.globalConfigReducer,
  );
  // const [isSticky, setSticky] = useState<boolean>(false);
  const [onFooter, setOnFooter] = useState<any>({
    isOnFoot: false,
    bottom: 0,
  });
  const heightFoot = 416;

  useEffect(() => {
    window.addEventListener('scroll', _handleScroll);
    return () => window.removeEventListener('scroll', _handleScroll);
  }, []);

  {
    /*
  const reRouteToRoot = (param = '') => {
    let redirect_url = window.location.origin;
    if (param != '') {
      redirect_url = window.location.origin + '/' + param + '/';
    }
    window.location.href = redirect_url;
  };
*/
  }

  const _handleScroll = () => {
    if (ref && ref.current) {
      const offset = window.scrollY;
      const siteHeight = window.innerHeight;
      const height = ref.current.clientHeight;

      if (offset > height) {
        const distanceToBottom = siteHeight - offset + height;
        if (distanceToBottom <= heightFoot) {
          setOnFooter({
            ...onFooter,
            isOnFoot: true,
            bottom: heightFoot - distanceToBottom,
          });
        } else {
          setOnFooter({
            ...onFooter,
            isOnFoot: false,
            bottom: 0,
          });
        }
        // setSticky(true);
      } else {
        // setSticky(false);
      }
    }
  };

  const handleClickUserIc = () => {
    const AUTH_TOKEN = localStorage.getItem('sundoraToken');
    AUTH_TOKEN
      ? history.push('/account/my-account')
      : dispatch(action.toggleModalLoginActions(true));
  };

  const handlePhoneNumberClick = () => {
    window.location.href = `tel:${PHONE_CONTACT}`;
  };

  return (
    <>
      <div className="banner-item banner-mb-with-shoppingcart">
        <ul>
          {/*
          <li>
            <a
              className="kids-menu-item"
              onClick={() => reRouteToRoot(PREFIX_URL_KIDS)}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <img src={SundoraKidsIcon} style={{ height: '22px', marginRight: '5px' }} />
              SUNDORA KIDS
            </a>
          </li>
          <li>
            <a
              className="beauty-menu-item"
              onClick={() => reRouteToRoot(PREFIX_URL_DEFAULT)}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <img src={SundoraBeautyIcon} style={{ height: '22px', marginRight: '5px' }} />
              SUNDORA BEAUTY
            </a>
          </li>
          <li>
            <a
              className="home-menu-item"
              onClick={() => reRouteToRoot(PREFIX_URL_HOME)}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <img src={SundoraBeautyIcon} style={{ height: '22px', marginRight: '5px' }} />
              HOME
            </a>
          </li>
          <li>
            <a
              className="sports-menu-item"
              onClick={() => reRouteToRoot(PREFIX_URL_SPORTS)}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <img src={SundoraBeautyIcon} style={{ height: '22px', marginRight: '5px' }} />
              SPORTS
            </a>
          </li>*/}

          <li>
            <img src={PhoneIcon} width="12px" alt="phone-contact" />
            <a href={`tel:${PHONE_CONTACT}`} onClick={handlePhoneNumberClick}>
              {' '}
              +880 1966 444455
            </a>
            {/* </Link> */}
          </li>
          <li>
            <img src={FreeShippingIcon} width="30px" alt="free-shipping" />
            <div>free shipping*</div>
          </li>
          <li>
            <img src={AuthIcon} width="16px" alt="100-authentic" />
            <div>100% authentic</div>
          </li>
        </ul>
      </div>
      <div className={`banner-block`} ref={ref}>
        <div></div>
        <div className="banner-content">
          <h1 className="banner-item banner-with-logo">
            {prefix == PREFIX_URL_KIDS ? (
              <Link to={`/${PREFIX_URL_KIDS}`}>
                <img
                  src={
                    globalConfigReducer?.logo
                      ? globalConfigReducer?.logo?.original
                      : SundoraKidsLogo
                  }
                  width={135}
                  alt="sundora-logo"
                />
              </Link>
            ) : prefix == PREFIX_URL_HOME ? (
              <Link to={`/${PREFIX_URL_HOME}`}>
                <img
                  src={
                    globalConfigReducer?.logo
                      ? globalConfigReducer?.logo?.original
                      : SundoraHomeLogo
                  }
                  width={135}
                  height={83.5}
                  alt="sundora-logo"
                />
              </Link>
            ) : prefix == PREFIX_URL_SPORTS ? (
              <Link to={`/${PREFIX_URL_SPORTS}`}>
                <img
                  src={
                    globalConfigReducer?.logo
                      ? globalConfigReducer?.logo?.original
                      : SundoraSportLogo
                  }
                  width={135}
                  height={83.5}
                  alt="sundora-logo"
                />
              </Link>
            ) : (
              <Link to="/">
                <img
                  src={
                    globalConfigReducer?.logo
                      ? globalConfigReducer?.logo?.original
                      : SundoraLogo
                  }
                  width={135}
                  height={83.5}
                  alt="sundora-logo"
                />
              </Link>
            )}
          </h1>
        </div>
        <div className="banner-with-detail">
          <SearchBox />
          <button className="btn-login" onClick={handleClickUserIc}>
            <img src={UserIcon} alt="user-icon" />
          </button>
          {userReducer?.user?.first_name
            ? `Hi ${userReducer.user?.first_name}`
            : userReducer?.user?.guest
            ? 'Guest'
            : ''}
          <BagIcon />
        </div>
      </div>
    </>
  );
}

export default Banner;
