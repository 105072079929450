import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GET_BASIC_SETTINGS, GET_FOOTER, GET_NAV } from '../../config';
import { setGlobalConfigAction } from '../../redux/globalConfig/actions';
import * as action from '../../redux/home/actions';
import { prefix } from './usePrefixSegment';

function useGlobalConfig() {
  const dispatch = useDispatch();
  const [siteUnderMaintenance, setSiteUnderMaintenance] = useState(false);

  const handlePromiseResult = (results: any) => {
    const nav: any = results?.[0]?.data?.data;
    const footer: any = results?.[1]?.data?.data;
    const basicSettings: any = results?.[2]?.data?.data;

    setSiteUnderMaintenance(basicSettings?.site_under_maintenance);
    dispatch(
      setGlobalConfigAction({
        logo:
          localStorage.getItem('sundoraToken') && basicSettings?.logo_logged
            ? basicSettings?.logo_logged
            : basicSettings?.logo,
        nav,
        footer,
        basicSettings,
      }),
    );
    dispatch(action.setGlobalHeaderMessage(nav?.highlight_message));
  };

  useEffect(() => {
    const requestConfig: any = {};
    const sundoraToken: any = localStorage.getItem('sundoraToken');
    if (sundoraToken) {
      requestConfig.headers = {
        authorization: `Token ${sundoraToken}`,
      };
    }
    const navType = prefix == '' ? 'beauty' : 'kids';
    const nav: any = axios.get(GET_NAV + '?type=' + navType, requestConfig);
    const footer: any = axios.get(GET_FOOTER + '?type=' + navType, requestConfig);
    const basicSettings: any = axios.get(GET_BASIC_SETTINGS, requestConfig);

    Promise.all([nav, footer, basicSettings])
      .then(function (results) {
        handlePromiseResult(results);
      })
      .catch((errors: any) => {
        if (errors?.response?.status == 401) {
          const nav: any = axios.get(GET_NAV + '?type=' + prefix);
          const footer: any = axios.get(GET_FOOTER + '?type=' + prefix);
          const basicSettings: any = axios.get(GET_BASIC_SETTINGS);
          Promise.all([nav, footer, basicSettings])
            .then(function (results) {
              handlePromiseResult(results);
            })
            .catch();
        }
      });
  }, []);

  return [siteUnderMaintenance];
}

export default useGlobalConfig;
